import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom"
import axios from "axios"
import moment from "moment"
import './App.css'

function App() {
  return (
    <Router>
      <div className="app-main">
        <Switch>
          <Route path="/auth/login">
            <Login/>
          </Route>
          <Route path="/pair/message">
            <PairMessage/>
          </Route>
          <Route path="/wish">
            <Wish/>
          </Route>
          <Route path="/daily">
            <Daily/>
          </Route>
          <Route path="/pair/wish">
            <PairWish/>
          </Route>
          <Route path="/pair/daily">
            <PairDaily/>
          </Route>
          <Route path="/">
            <Default/>
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    redirectToPairMessage: false,
  }

  handleUsernameChange(e) {
    this.setState({
      username: e.target.value
    })
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value
    })
  }

  doLogin() {
    const username = this.state.username
    const password = this.state.password
    const self = this
    axios.post('/api/auth/v1/login', {
      username: username,
      password: password,
    })
    .then(function (response) {
      localStorage.setItem("token", response.data.AccessToken.Raw)
      localStorage.setItem("userId", response.data.User.ID)
      localStorage.setItem("userNickname", response.data.User.Nickname)
      self.setState({
        redirectToPairMessage: true
      })
    })
    .catch(function (error) {
      // handle error
      alert("登录失败")
    })
  }

  render() {
    const {redirectToPairMessage} = this.state
    if (redirectToPairMessage || localStorage.getItem("token")) {
      return (
        <Redirect to="/pair/message"/>
      )
    }
    return (
      <div className="login-main">
        <div className="login">
          <div className="title">miitu</div>
          <div className="tips">Meet U, Love U, Miss U</div>
          <div className="announcement">v1.0.0.20220324</div>
          <input placeholder="请输入你的用户名" type="text" name="username" value={this.state.username} onChange={this.handleUsernameChange.bind(this)}/>
          <input placeholder="请输入你的密码" type="password" name="password" value={this.state.password} onChange={this.handlePasswordChange.bind(this)}/>
          <button type="button" onClick={this.doLogin.bind(this)}>登录</button>
        </div>
      </div>
    )
  }
}

class PairMessage extends React.Component {
  state = {
    // set by loadPair
    pair: {},

    // initialized by initMessages, set by loadPreviousMessages and loadNextMessages, reset by reloadMessages
    messages: [],
    empty: false,

    // prev
    firstId: 0,
    noMorePrev: false,
    loadPrevDisabled: false,
    // next
    lastId: 0,
    noMore: false,
    loadMoreDisabled: false,

    redirectToLogin: false,
    message: "",

    // auto load messages
    loader: null,

    // alert message
    alert: "",
  }

  loadPair() {
    const token = localStorage.getItem("token")
    let self = this
    return axios.post('/api/pair/v1/pair', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState(state => ({
        pair: response.data,
      }))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  initMessages() {
    this.setState({
      loadPrevDisabled: true,
      loadMoreDisabled: true,
    })
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/messages/by-time', {
      back: true,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState({
          messages: response.data,
          firstId: response.data[0].ID,
          noMorePrev: false,
          lastId: response.data[response.data.length - 1].ID,
          noMore: false,
        })
      } else {
        self.setState({
          empty: true,
          noMorePrev: true,
          noMore: true,
        })
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
    .then(function () {
      self.setState({
        loadPrevDisabled: false,
        loadMoreDisabled: false,
      }, () => {
        self.scrollToBottom()
      })
    })
  }

  loadPreviousMessages() {
    if (this.state.noMorePrev) {
      this.scrollToTop()
      return
    }
    this.setState({
      loadPrevDisabled: true,
    })
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/messages/by-id', {
      from: self.state.firstId,
      back: true,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState(state => ({
          messages: [...response.data, ...state.messages],
          firstId: response.data[0].ID,
          noMorePrev: false,
        }))
      } else {
        self.setState({
          noMorePrev: true,
        })
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
    .then(function () {
      self.setState({
        loadPrevDisabled: false,
      }, () => {
        self.scrollToTop()
      })
    })
  }

  loadNextMessages(doScroll) {
    if (this.state.redirectToLogin) {
      return
    }
    this.setState({
      loadMoreDisabled: true,
    })
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/messages/by-id', {
      from: self.state.lastId,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState(state => ({
          messages: [...state.messages, ...response.data],
          lastId: response.data[response.data.length - 1].ID,
          noMore: false,
        }))
      } else {
        self.setState({
          noMore: true,
        })
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
    .then(function () {
      self.setState({
        loadMoreDisabled: false,
      }, () => {
        if (doScroll) {
          self.scrollToBottom()
        } else {
          if (!self.state.noMore && !self.state.redirectToLogin) {
            self.setState({
              alert: "你有新的未读消息"
            })
          }
        }
      })
    })
  }

  hideAlert() {
    let self = this
    this.setState({
      alert: ""
    }, () => {
      self.scrollToBottom()
    })
  }

  // unused
  reloadMessages() {
    this.setState({
      messages: [],
      empty: false,
      // prev
      firstId: 0,
      noMorePrev: false,
      // next
      lastId: 0,
      noMore: false,
    }, () => {
      this.initMessages()
    })
  }

  handleMessageChange(e) {
    this.setState({
      message: e.target.value
    })
  }

  doSendMessage() {
    const token = localStorage.getItem("token")
    const message = this.state.message
    const self = this
    axios.post('/api/pair/v1/messages/create', {
      message: message,
      type: "default",
    }, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState({
        message: ""
      })
      self.loadNextMessages(true)
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert("登录超时，请重新登录")
      } else {
        alert("发送失败")
      }
    })
  }

  doLogout() {
    const token = localStorage.getItem("token")
    const self = this
    axios.post('/api/auth/v1/logout', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
    .catch(function (error) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
  }

  scrollToTop() {
    if (this.messagesStart) {
      this.messagesStart.scrollIntoView({
        behavior: "smooth",
      })
    }
  }

  scrollToBottom() {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({
        behavior: "smooth",
      })
    }
  }

  async componentDidMount() {
    await this.loadPair()
    this.initMessages()
    this.scrollToBottom()
    if (!this.state.loader) {
      const self = this
      const loader = setInterval(function () {
        self.loadNextMessages(false)
      }, 10000)
      this.setState({
        loader: loader,
      })
    }
  }

  async componentWillUnmount() {
    if (this.state.loader) {
      clearInterval(this.state.loader)
    }
  }

  render() {
    const {redirectToLogin} = this.state
    if (redirectToLogin) {
      return (
        <Redirect to="/auth/login"/>
      )
    }
    return (
      <div className="workspace-main">
        {
          this.state.alert
            ? <div className="workspace-alert" onClick={this.hideAlert.bind(this)}>{this.state.alert}</div>
            : <div/>
        }
        <div className="workspace-l">
          <div className="avatar">
            <img width="90" height="90" src={`/user_${localStorage.getItem("userId")}.png`}/>
          </div>
          <div className="username">{localStorage.getItem("userNickname")}</div>
          <div className="menu">
            <Link className="item active" to="/pair/message">
              <span className="item-icon">H</span>
              <span className="item-title">会话</span>
            </Link>
            <Link className="item" to="/wish">
              <span className="item-icon">J</span>
              <span className="item-title">记仇小笨笨</span>
            </Link>
            <Link className="item" to="/daily">
              <span className="item-icon">R</span>
              <span className="item-title">日记</span>
            </Link>
          </div>
          <div className="logout">
            <button onClick={this.doLogout.bind(this)}>登出</button>
          </div>
        </div>
        <div className="workspace-m">
          <div className="top-bar">
            <div className="avatar">
              <img width="60" height="60" src={`/user_${this.state.pair.UserLeft ? this.state.pair.UserLeft.User.ID : 1}.png`}/>
            </div>
            <div className="username">
              {this.state.pair.UserLeft ? this.state.pair.UserLeft.User.Nickname : ""}
              <Link className="link" to="/pair/wish">TA的记仇小笨笨</Link>
              <Link className="link" to="/pair/daily">TA的日记</Link>
            </div>
          </div>
          <div className="workspace-body">
            <div className="load-prev" onClick={this.loadPreviousMessages.bind(this)}>⬆</div>
            <div className="load-next" onClick={this.loadNextMessages.bind(this, true)}>⬇</div>
            <div className="show-messages">
              <div ref={(el) => {
                this.messagesStart = el
              }}>
              </div>
              {
                this.state.messages.map((m, i) => {
                  return (
                    <div key={i} className={`message ${m.PairUser.User.ID === this.state.pair.UserLeft.User.ID ? "message-left" : "message-right"}`}>
                      <div className="message-avatar">
                        <img width="40" height="40" src={`/user_${m.PairUser.User.ID}_msg.png`}/>
                      </div>
                      <div className="message-box">
                        <div className="message-time">
                          {moment.unix(m.CreatedAt).format()}
                        </div>
                        <div className="message-text">
                          {m.Message}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <div ref={(el) => {
                this.messagesEnd = el
              }}>
              </div>
            </div>
            <div className="send-message">
              <textarea name="message" value={this.state.message} onChange={this.handleMessageChange.bind(this)}/>
              <button type="button" disabled={!this.state.message} onClick={this.doSendMessage.bind(this)}>发送</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Wish extends React.Component {
  state = {
    // set by loadPair
    pair: {},

    // set by getWishes
    wishes: [],
    from: 0,

    wish: "",

    redirectToLogin: false,
  }

  loadPair() {
    const token = localStorage.getItem("token")
    let self = this
    return axios.post('/api/pair/v1/pair', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState(state => ({
        pair: response.data,
      }))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  getWishes() {
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/wishes', {
      from: self.state.from,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState(state => ({
          wishes: [...state.wishes, ...response.data],
          from: state.from + response.data.length,
        }))
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  handleContentChange(e) {
    this.setState({
      wish: e.target.value
    })
  }

  doPostWish() {
    const token = localStorage.getItem("token")
    const wish = this.state.wish
    const self = this
    axios.post('/api/pair/v1/wishes/create', {
      content: wish,
    }, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState({
        wish: "",
        wishes: [],
        from: 0,
      }, () => {
        self.getWishes()
      })
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert("登录超时，请重新登录")
      } else {
        alert("发布失败")
      }
    })
  }

  doLogout() {
    const token = localStorage.getItem("token")
    const self = this
    axios.post('/api/auth/v1/logout', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
    .catch(function (error) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
  }

  async componentDidMount() {
    await this.loadPair()
    this.getWishes()
  }

  async componentWillUnmount() {

  }

  render() {
    const {redirectToLogin} = this.state
    if (redirectToLogin) {
      return (
        <Redirect to="/auth/login"/>
      )
    }
    return (
      <div className="workspace-main">
        <div className="workspace-l">
          <div className="avatar">
            <img width="90" height="90" src={`/user_${localStorage.getItem("userId")}.png`}/>
          </div>
          <div className="username">{localStorage.getItem("userNickname")}</div>
          <div className="menu">
            <Link className="item" to="/pair/message">
              <span className="item-icon">H</span>
              <span className="item-title">会话</span>
            </Link>
            <Link className="item active" to="/wish">
              <span className="item-icon">J</span>
              <span className="item-title">记仇小笨笨</span>
            </Link>
            <Link className="item" to="/daily">
              <span className="item-icon">R</span>
              <span className="item-title">日记</span>
            </Link>
          </div>
          <div className="logout">
            <button onClick={this.doLogout.bind(this)}>登出</button>
          </div>
        </div>
        <div className="workspace-m">
          <div className="top-bar">
            <div className="avatar">
              <img width="60" height="60" src={`/user_${this.state.pair.UserLeft ? this.state.pair.UserLeft.User.ID : 1}.png`}/>
            </div>
            <div className="username">
              {this.state.pair.UserLeft ? this.state.pair.UserLeft.User.Nickname : ""}
              <Link className="link" to="/pair/wish">TA的记仇小笨笨</Link>
              <Link className="link" to="/pair/daily">TA的日记</Link>
            </div>
          </div>
          <div className="workspace-body">
            <div className="load-my-next-wishes" onClick={this.getWishes.bind(this)}>⬇</div>
            <div className="show-my-wishes">
              <div ref={(el) => {
                this.wishesStart = el
              }}>
              </div>
              {
                this.state.wishes.length > 0
                  ? this.state.wishes.map((w, i) => {
                    return (
                      <div key={i} className="wish">
                        <div className="wish-time">
                          {moment.unix(w.CreatedAt).format()}
                        </div>
                        <div className="wish-content">
                          {w.Content}
                        </div>
                      </div>
                    )
                  }) : <div className="empty">还没有内容，去看看 <Link className="link" to="/pair/wish">TA的记仇小笨笨</Link> 吧</div>
              }
              <div ref={(el) => {
                this.wishesEnd = el
              }}>
              </div>
            </div>
            <div className="post-wish">
              <textarea placeholder="这里是你的记仇小笨笨，写下你要记的仇吧" name="content" value={this.state.wish} onChange={this.handleContentChange.bind(this)}/>
              <button type="button" disabled={!this.state.wish} onClick={this.doPostWish.bind(this)}>发布</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Daily extends React.Component {
  state = {
    // set by loadPair
    pair: {},

    // set by getDaily
    daily: [],
    from: 0,

    draft: "",
    images: [],
    audio: "",

    recording: false,
    recorder: null,
    audioData: [],

    // 当前展示的图片
    image: "",

    redirectToLogin: false,
  }

  loadPair() {
    const token = localStorage.getItem("token")
    let self = this
    return axios.post('/api/pair/v1/pair', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState(state => ({
        pair: response.data,
      }))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  getDaily() {
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/daily', {
      from: self.state.from,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState(state => ({
          daily: [...state.daily, ...response.data],
          from: state.from + response.data.length,
        }))
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  handleContentChange(e) {
    this.setState({
      draft: e.target.value
    })
  }

  uploadImages(e) {
    const self = this
    const token = localStorage.getItem("token")
    const files = e.target.files
    if (files.length === 0) {
      self.setState({
        images: [],
      })
      return
    }
    if (files.length > 3) {
      alert("最多只能发布三张图片")
      return
    }
    for (let i = 0; i < files.length; ++i) {
      if (files[i].size > 10000000) {
        alert("不能上传超过 10M 的图片")
        return
      }
    }
    const formData = new FormData()
    for (let i = 0; i < files.length; ++i) {
      formData.append(`images[${i}]`, files[i])
    }
    axios.post('/api/images', formData, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState({
          images: response.data,
        })
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert("登录超时，请重新登录")
      } else {
        alert("图片上传失败")
      }
    })
  }

  setImage(img) {
    this.setState({
      image: img,
    })
  }

  toggleRecord() {
    if (this.state.recording) {
      this.stopRecord()
    } else {
      this.startRecord()
    }
  }

  startRecord() {
    this.setState({
      recording: true,
    })
    const self = this
    const token = localStorage.getItem("token")
    navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
      self.setState({
        recorder: new MediaRecorder(stream),
        audioData: [],
      }, () => {
        self.state.recorder.onerror = function () {
          alert("未能正确录音")
        }
        self.state.recorder.ondataavailable = function (e) {
          self.state.audioData.push(e.data)
        }
        self.state.recorder.onstop = function () {
          const audioData = new Blob(self.state.audioData)

          const audio = document.getElementById('record')
          audio.src = window.URL.createObjectURL(audioData)

          const formData = new FormData()
          formData.append("audio", audioData)
          axios.post('/api/audio', formData, {
            headers: {
              "miitu-whoami": token,
            }
          })
          .then(function (response) {
            const newToken = response.headers["miitu-youare"]
            if (newToken) {
              localStorage.setItem("token", newToken)
            }

            if (response.data.length > 0) {
              self.setState({
                audio: response.data,
              })
            }
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              alert("登录超时，请重新登录")
            } else {
              alert("录音上传失败")
            }
          })
        }
        self.state.recorder.start()
        setTimeout(() => {
          self.stopRecord()
        }, 60000)
      })
    })
  }

  stopRecord() {
    this.setState({
      recording: false,
    })
    if (this.state.recorder) {
      this.state.recorder.stop()
      this.setState({
        recorder: null,
      })
    }
  }

  doPostDaily() {
    const token = localStorage.getItem("token")
    const daily = this.state.draft
    const images = this.state.images
    const audio = this.state.audio
    const self = this
    axios.post('/api/pair/v1/daily/create', {
      content: daily,
      images: images,
      audio: audio,
    }, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState({
        draft: "",
        daily: [],
        images: [],
        audio: "",
        from: 0,
      }, () => {
        self.getDaily()
      })
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert("登录超时，请重新登录")
      } else {
        alert("发布失败")
      }
    })
  }

  doLogout() {
    const token = localStorage.getItem("token")
    const self = this
    axios.post('/api/auth/v1/logout', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
    .catch(function (error) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
  }

  async componentDidMount() {
    await this.loadPair()
    this.getDaily()
  }

  async componentWillUnmount() {

  }

  render() {
    const {redirectToLogin} = this.state
    if (redirectToLogin) {
      return (
        <Redirect to="/auth/login"/>
      )
    }
    return (
      <div className="workspace-main">
        <div className="workspace-l">
          <div className="avatar">
            <img width="90" height="90" src={`/user_${localStorage.getItem("userId")}.png`}/>
          </div>
          <div className="username">{localStorage.getItem("userNickname")}</div>
          <div className="menu">
            <Link className="item" to="/pair/message">
              <span className="item-icon">H</span>
              <span className="item-title">会话</span>
            </Link>
            <Link className="item" to="/wish">
              <span className="item-icon">J</span>
              <span className="item-title">记仇小笨笨</span>
            </Link>
            <Link className="item active" to="/daily">
              <span className="item-icon">R</span>
              <span className="item-title">日记</span>
            </Link>
          </div>
          <div className="logout">
            <button onClick={this.doLogout.bind(this)}>登出</button>
          </div>
        </div>
        <div className="workspace-m">
          <div className="top-bar">
            <div className="avatar">
              <img width="60" height="60" src={`/user_${this.state.pair.UserLeft ? this.state.pair.UserLeft.User.ID : 1}.png`}/>
            </div>
            <div className="username">
              {this.state.pair.UserLeft ? this.state.pair.UserLeft.User.Nickname : ""}
              <Link className="link" to="/pair/wish">TA的记仇小笨笨</Link>
              <Link className="link" to="/pair/daily">TA的日记</Link>
            </div>
          </div>
          <div className="workspace-body">
            {
              this.state.image ?
                <div className="show-img" onClick={this.setImage.bind(this, "")}>
                  <img src={this.state.image}/>
                </div>
                : <div/>
            }
            <div className="load-my-next-daily" onClick={this.getDaily.bind(this)}>⬇</div>
            <div className="show-my-daily">
              <div ref={(el) => {
                this.dailyStart = el
              }}>
              </div>
              {
                this.state.daily.length > 0
                  ? this.state.daily.map((d, i) => {
                    return (
                      <div key={i} className="daily">
                        <div className="daily-time">
                          {moment.unix(d.CreatedAt).format()}
                        </div>
                        {
                          d.Audio && d.Audio.length > 0
                            ? <div className="audio">
                              <audio controls src={d.Audio}></audio>
                            </div>
                            : <div/>
                        }
                        {
                          d.Images && d.Images.length > 0
                            ? <div className="images">
                              {
                                d.Images.map((img, j) => {
                                  return (
                                    <div key={j} className="image" onClick={this.setImage.bind(this, img)}>
                                      <img width="60" src={img}/>
                                    </div>
                                  )
                                })
                              }
                            </div> : <div/>
                        }
                        <div className="daily-content">
                          {d.Content}
                        </div>
                      </div>
                    )
                  }) : <div className="empty">还没有内容，去看看 <Link className="link" to="/pair/daily">TA的日记</Link> 吧</div>
              }
              <div ref={(el) => {
                this.dailyEnd = el
              }}>
              </div>
            </div>
            <div className="post-daily">
              <input id="images" className="upload-images" type="file" accept="image/*" multiple onChange={this.uploadImages.bind(this)}/>
              <label className="upload-images" htmlFor="images">上传图片</label>
              <div className="images">
                {
                  this.state.images.length > 0
                    ? this.state.images.map((d, i) => {
                      return (
                        <div key={i} className="image">
                          <img width="40" src={d}/>
                        </div>
                      )
                    }) : <div/>
                }
              </div>
              <div className="record">
                <button className="toggle" onClick={this.toggleRecord.bind(this)}>{this.state.recording ? "结束录音" : "开始录音"}</button>
                <audio id="record" controls></audio>
              </div>
              <textarea placeholder="在这里写下你的日记" name="content" value={this.state.draft} onChange={this.handleContentChange.bind(this)}/>
              <button className="submit" type="button" disabled={!this.state.draft} onClick={this.doPostDaily.bind(this)}>发布</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class PairWish extends React.Component {
  state = {
    // set by loadPair
    pair: {},

    // set by getPairWishes
    wishes: [],
    from: 0,

    redirectToLogin: false,
  }

  loadPair() {
    const token = localStorage.getItem("token")
    let self = this
    return axios.post('/api/pair/v1/pair', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState(state => ({
        pair: response.data,
      }))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  getPairWishes() {
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/pair-wishes', {
      from: self.state.from,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState(state => ({
          wishes: [...state.wishes, ...response.data],
          from: state.from + response.data.length,
        }))
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  doLogout() {
    const token = localStorage.getItem("token")
    const self = this
    axios.post('/api/auth/v1/logout', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
    .catch(function (error) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
  }

  async componentDidMount() {
    await this.loadPair()
    this.getPairWishes()
  }

  async componentWillUnmount() {

  }

  render() {
    const {redirectToLogin} = this.state
    if (redirectToLogin) {
      return (
        <Redirect to="/auth/login"/>
      )
    }
    return (
      <div className="workspace-main">
        <div className="workspace-l">
          <div className="avatar">
            <img width="90" height="90" src={`/user_${localStorage.getItem("userId")}.png`}/>
          </div>
          <div className="username">{localStorage.getItem("userNickname")}</div>
          <div className="menu">
            <Link className="item" to="/pair/message">
              <span className="item-icon">H</span>
              <span className="item-title">会话</span>
            </Link>
            <Link className="item" to="/wish">
              <span className="item-icon">J</span>
              <span className="item-title">记仇小笨笨</span>
            </Link>
            <Link className="item" to="/daily">
              <span className="item-icon">R</span>
              <span className="item-title">日记</span>
            </Link>
          </div>
          <div className="logout">
            <button onClick={this.doLogout.bind(this)}>登出</button>
          </div>
        </div>
        <div className="workspace-m">
          <div className="top-bar">
            <div className="avatar">
              <img width="60" height="60" src={`/user_${this.state.pair.UserLeft ? this.state.pair.UserLeft.User.ID : 1}.png`}/>
            </div>
            <div className="username">
              {this.state.pair.UserLeft ? this.state.pair.UserLeft.User.Nickname : ""}
              <Link className="link active" to="/pair/wish">TA的记仇小笨笨</Link>
              <Link className="link" to="/pair/daily">TA的日记</Link>
            </div>
          </div>
          <div className="workspace-body">
            <div className="load-next-wishes" onClick={this.getPairWishes.bind(this)}>⬇</div>
            <div className="show-wishes">
              <div ref={(el) => {
                this.wishesStart = el
              }}>
              </div>
              {
                this.state.wishes.length > 0
                  ? this.state.wishes.map((w, i) => {
                    return (
                      <div key={i} className="wish">
                        <div className="wish-time">
                          {moment.unix(w.CreatedAt).format()}
                        </div>
                        <div className="wish-content">
                          {w.Content}
                        </div>
                      </div>
                    )
                  }) : <div className="empty">还没有内容</div>
              }
              <div ref={(el) => {
                this.wishesEnd = el
              }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class PairDaily extends React.Component {
  state = {
    // set by loadPair
    pair: {},

    // set by getPairDaily
    daily: [],
    from: 0,

    image: "",

    redirectToLogin: false,
  }

  loadPair() {
    const token = localStorage.getItem("token")
    let self = this
    return axios.post('/api/pair/v1/pair', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      self.setState(state => ({
        pair: response.data,
      }))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  getPairDaily() {
    const token = localStorage.getItem("token")
    let self = this
    axios.post('/api/pair/v1/pair-daily', {
      from: self.state.from,
    }, {
      headers: {
        "miitu-whoami": token,
      },
    })
    .then(function (response) {
      const newToken = response.headers["miitu-youare"]
      if (newToken) {
        localStorage.setItem("token", newToken)
      }

      if (response.data.length > 0) {
        self.setState(state => ({
          daily: [...state.daily, ...response.data],
          from: state.from + response.data.length,
        }))
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userNickname")
        self.setState({
          redirectToLogin: true
        })
      }
    })
  }

  setImage(img) {
    this.setState({
      image: img,
    })
  }

  doLogout() {
    const token = localStorage.getItem("token")
    const self = this
    axios.post('/api/auth/v1/logout', {}, {
      headers: {
        "miitu-whoami": token,
      }
    })
    .then(function (response) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
    .catch(function (error) {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userNickname")
      self.setState({
        redirectToLogin: true
      })
    })
  }

  async componentDidMount() {
    await this.loadPair()
    this.getPairDaily()
  }

  async componentWillUnmount() {

  }

  render() {
    const {redirectToLogin} = this.state
    if (redirectToLogin) {
      return (
        <Redirect to="/auth/login"/>
      )
    }
    return (
      <div className="workspace-main">
        <div className="workspace-l">
          <div className="avatar">
            <img width="90" height="90" src={`/user_${localStorage.getItem("userId")}.png`}/>
          </div>
          <div className="username">{localStorage.getItem("userNickname")}</div>
          <div className="menu">
            <Link className="item" to="/pair/message">
              <span className="item-icon">H</span>
              <span className="item-title">会话</span>
            </Link>
            <Link className="item" to="/wish">
              <span className="item-icon">J</span>
              <span className="item-title">记仇小笨笨</span>
            </Link>
            <Link className="item" to="/daily">
              <span className="item-icon">R</span>
              <span className="item-title">日记</span>
            </Link>
          </div>
          <div className="logout">
            <button onClick={this.doLogout.bind(this)}>登出</button>
          </div>
        </div>
        <div className="workspace-m">
          <div className="top-bar">
            <div className="avatar">
              <img width="60" height="60" src={`/user_${this.state.pair.UserLeft ? this.state.pair.UserLeft.User.ID : 1}.png`}/>
            </div>
            <div className="username">
              {this.state.pair.UserLeft ? this.state.pair.UserLeft.User.Nickname : ""}
              <Link className="link" to="/pair/wish">TA的记仇小笨笨</Link>
              <Link className="link active" to="/pair/daily">TA的日记</Link>
            </div>
          </div>
          <div className="workspace-body">
            {
              this.state.image ?
                <div className="show-img" onClick={this.setImage.bind(this, "")}>
                  <img src={this.state.image}/>
                </div>
                : <div/>
            }
            <div className="load-next-daily" onClick={this.getPairDaily.bind(this)}>⬇</div>
            <div className="show-daily">
              <div ref={(el) => {
                this.dailyStart = el
              }}>
              </div>
              {
                this.state.daily.length > 0
                  ? this.state.daily.map((d, i) => {
                    return (
                      <div key={i} className="daily">
                        <div className="daily-time">
                          {moment.unix(d.CreatedAt).format()}
                        </div>
                        {
                          d.Audio && d.Audio.length > 0
                            ? <div className="audio">
                              <audio controls src={d.Audio}></audio>
                            </div>
                            : <div/>
                        }
                        {
                          d.Images && d.Images.length > 0
                            ? <div className="images">
                              {
                                d.Images.map((img, j) => {
                                  return (
                                    <div key={j} className="image" onClick={this.setImage.bind(this, img)}>
                                      <img width="60" src={img}/>
                                    </div>
                                  )
                                })
                              }
                            </div> : <div/>
                        }
                        <div className="daily-content">
                          {d.Content}
                        </div>
                      </div>
                    )
                  }) : <div className="empty">还没有内容</div>
              }
              <div ref={(el) => {
                this.dailyEnd = el
              }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function Default() {
  return <Redirect to="/auth/login"/>
}

export default App
